(function () {
	const headerElement = document.querySelector("header .et_pb_section");
	const scrollThreshold = 90;
	if (!headerElement) return;
	const setBackgroundVisibility = () => {
		headerElement.classList.toggle("at-top", window.scrollY <= scrollThreshold);
		headerElement.classList.toggle(
			"not-at-top",
			window.scrollY > scrollThreshold,
		);
	};

	document.addEventListener("scroll", function () {
		setBackgroundVisibility();
	});

	setBackgroundVisibility();
})();

const readMoreButtons = Array.from(
	document.querySelectorAll(
		'html[lang="es-CR"] .more-link, html[lang="es-CR"] .et_pb_more_button',
	),
);
for (const btn of readMoreButtons) {
	btn.textContent = "Más información";
}
